<template>
  <v-row class="notifications">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">notifications</div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15">
        <div class="px-sm-10 px-3 py-2 accent d-flex flex-wrap justify-space-between">
          <v-select
            style="max-width: 160px"
            v-model="read"
            :items="selectRead"
            item-text="title"
            item-value="name"
            menu-props="auto"
            hide-details
            single-line
            outlined
            dense
            @change="getData"
            height="40"
            color="input"
            class="white"
          ></v-select>
          <v-btn @click="readAll" height="40" text color="input">Read All</v-btn>
        </div>
        <v-divider></v-divider>
        <div class="px-sm-10 py-sm-4 px-3 py-2">
          <v-card
            flat
            height="70vh"
            min-height="260"
            v-if="!data.result.length"
            class="pa-3 d-flex align-center justify-center flex-column"
          >
            <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
            <div class="text-center text-h5 font-weight-bold mt-8">Looks like you don't have any notifications</div>
          </v-card>
          <v-data-table
            v-else
            hide-default-footer
            hide-default-header
            :headers="headers"
            :items="data.result"
            item-key="id"
            :items-per-page="+itemsPerPage"
            :height="`calc(100vh - 318px)`"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" class="link" @click="readItem(item)">
                  <td>
                    <div class="d-flex justify-space-between">
                      <div>
                        <div>{{ item.message }}</div>
                        <div class="opasity--text">{{ new Date(item.datetime).toLocaleString() }}</div>
                      </div>
                      <div v-if="!item.is_read" class="mt-1 input status rounded-circle"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <div class="mt-2 d-flex flex-wrap align-center justify-end">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    menu-props="auto"
                    hide-details
                    single-line
                    dense
                    @change="getData"
                  ></v-select>
                </div>
                <div>
                  <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                  <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      itemsPerPage: Number(this.$route.query.page_size) || 10,
      page: Number(this.$route.query.page) || 1,
      read: this.$route.query.unread_only || 'all',
      selectRead: [
        { name: 'all', title: 'All' },
        { name: 'true', title: 'Unread' },
        { name: 'false', title: 'Read' },
      ],
      headers: [{ text: 'Message', value: 'message', sortable: false }],
      statusError: null,
      error: null,
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getPagination');
    this.$store.dispatch('getNotificationsCount');
  },
  methods: {
    notifi(btn) {
      if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getNotificationsList', {
          pageSize: this.itemsPerPage,
          page: this.page,
          read: this.read,
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
    async readItem(item) {
      if (!item.is_read) {
        await this.$store.dispatch('notificationsRead', item.id).then(() => {
          this.getData();
        });
      }
    },
    async readAll() {
      await this.$store.dispatch('notificationsReadAll').then(() => {
        this.getData();
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.notificationsList;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
  },
  destroyed() {
    this.$store.dispatch('setNotificationsList', { result: [] });
  },
};
</script>

<style lang="scss">
.notifications {
  .status {
    min-width: 12px;
    width: 12px;
    height: 12px;
  }
  .v-input__append-inner {
    margin-top: 10px !important;
  }
}
</style>
